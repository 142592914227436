









































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import LinkedRetirementCardViewModel from '@/vue-app/view-models/components/goals-dashboard/linked-retirement-card-view-model';

@Component({
  name: 'LinkedRetirementFundCard',
  components: {
    LoadingDataCard,
    DetailsRetirementFundModal: () => import('./details-goals/DetailsRetirementFundModal.vue'),
    EditRetirementFundGoalPlanDialog: () => import('./edit-plan-goals/edit-retirement-fund-goal-plan/EditRetirementFundGoalPlanDialog.vue'),
    GoalTrackingTag: () => import('@/vue-app/components/goals-dashboard/GoalTrackingTag.vue'),
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
    WealthContributionDialog: () => import('@/vue-app/components/goals-dashboard/contribute-goals/wealth/WealthContributionDialog.vue'),
  },
})
export default class LinkedRetirementFundCard extends Vue {
  linked_retirement_fund_view_model = Vue.observable(new LinkedRetirementCardViewModel(this));

  created() {
    this.linked_retirement_fund_view_model.initialize();
  }
}
